<template>
    <div  class="container is-fluid " style="min-height:100vh;">
        <div class="columns mt-0 pt-2">
            <div class="column p-0">
                <div class="is-flex is-align-items-center">
                    <p class="is-size-5 mx-4 mt-1 has-text-info-dark is-pulled-left">
                        <span class="is-uppercase has-text-white">
                            <i class="fas fa-truck-loading"></i> Counting
                        </span>
                    </p>
                    <router-link :to="'/delivery-plan/' + $parent.event_details.ID " class="button is-outlined is-small ml-auto" >
                        Back to planning
                    </router-link>
                </div>
            </div>
        </div>

        <div v-if="$parent.are_jobs_loaded && proj_trc_jobs_got">
            <div  class="columns mt-0 pt-2">
                <div class="column pt-0 px-0 is-flex" style="">
                    <div v-if="direction" class="tabs is-small is-boxed mb-0 is-flex-grow-1" style="">
                        <ul class="horisontal-scroll planning-warehouses-tabs">
                            <li  v-for="key in Object.keys(warehouses)" :key="key"
                                :class="{'is-active' : selected_warehouse.ID == warehouses[key].ID }"
                                class="horisontal-item" >
                                <a @click="select_warehouse(key)" >
                                    <span class="">
                                        <p class="is-uppercase" v-html="'<small class=\'is-block is-capitalized \'>' + warehouses[key].Caption.replace(' - ', '</small>') ">
                                            {{selected_warehouse.ID ? selected_warehouse.ID: ''}}
                                        </p>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="is-flex is-flex-direction-column is-size-7 px-2 has-text-weight-semibold has-text-grey-light ml-auto" style="border-bottom: solid 1px #dbdbdb; min-width:fit-content;">
                        <span v-if="$parent.event_details.Address_Customer">
                            {{$parent.event_details.Address_Customer.Street}}, 
                            {{$parent.event_details.Address_Customer.Zip}}
                            <br/>
                            {{$parent.event_details.Address_Customer.City}}, 
                            {{$parent.event_details.Address_Customer.Country.Caption}}
                        </span>
                        <!-- <pre>
                            {{$parent.event_details.Address_Customer}}
                        </pre> -->
                    </div>
                    <div class="is-flex is-flex-direction-column px-2 has-text-centered" 
                        style="border-bottom: solid 1px #dbdbdb;">
                        <label class="switch">
                            <input type="checkbox" v-model="direction" > 
                            <!-- in change -> 1 or 0 -->
                            <span class="slider round"></span>
                        </label>
                        <small>
                            {{direction ? $t('delivery') : $t('return')}}
                        </small>
                        <!-- {{direction}} -->
                    </div>
                </div>
            </div>
            <div v-if="selected_warehouse.ID">
                <div v-if="truck_job_list.length > 0" class="is-flex is-flex-direction-column ">
                    <div v-for="(tj, index) in truck_job_list" :key="index" class="has-border p-2 mb-2 general-component-wrap-background">
                        <div class="columns is-mobile has-text-dark">
                            <div class="column is-4 is-size-7">
                                <div class="has-border has-background-white has-text-centered">
                                    <div class="is-flex is-justify-content-center">
                                        <truck v-if="tj.edit_mode" style="margin:0 auto;"
                                            :show="false"
                                            :job="tj"
                                            :weights="truckJobWeightVolume(tj)"
                                            :collapsed="false"
                                            :data="getTruckData(tj.details.truck_id, warehouses_parameters)"></truck>

                                    </div>
                                    <truck v-if="tj.edit_mode" 
                                        :show="false"
                                        :job="tj"
                                        :weights="truckJobWeightVolume(tj)"
                                        :collapsed="true"
                                        :data="getTruckData(tj.details.truck_id, warehouses_parameters)"></truck>
                                </div>
                                <div class="is-flex is-justify-content-space-between mt-2 has-border-bottom py-1"> 
                                    <small> Departs at: </small>
                                    <b class="is-align-self-flex-end"> {{ tj.details.departs_at | customdate }} </b>
                                </div>
                                <div class="is-flex is-justify-content-space-between  has-border-bottom py-1"> 
                                    <small> Arrives at: </small>
                                    <b class="is-align-self-flex-end"> {{ tj.details.arrives_at | customdate }} </b>
                                </div>
                                <div v-if="tj.details.destination" class="is-flex is-justify-content-space-between  has-border-bottom py-1"> 
                                    <small> Destination: </small>
                                    <b class="is-align-self-flex-end"> {{ destination_name(tj.details.destination) }} </b>
                                </div>
                                <div v-if="tj.details.destination" class="is-flex is-justify-content-space-between  has-border-bottom py-1"> 
                                    <small>Contact person</small>
                                    <b class="has-text-right is-align-self-flex-end" style="align-self: end" 
                                        v-html="getParameterVisibleFields(tj.details.contact_person, warehouses_parameters, '<br>')">
                                    </b>
                                </div>
                            </div>
                            <div class="column is-8 is-flex is-flex-direction-column">
                                <div class="is-flex is-flex-direction-row-reverse">
                                    <!-- {{tj.show_search}} -->
                                    <a v-if="!tj.show_search" @click.prevent="toggle_search(tj.guid, true)" class="button is-white mb-2 is-small is-pulled-right">
                                        <i class="fas fa-plus mr-1"></i> Add material
                                    </a>
                                    <div v-else style="position:relative;">
                                        <div class="field mr-2">
                                            <div class="control">
                                                <input v-model="tj.search_text" class="input is-small " type="text" placeholder="Search material">
                                            </div>
                                        </div>
                                        <div style="position:absolute; right:8px; bottom:-190px; height:200px; width:600px; z-index:9; overflow-y:scroll;"
                                                class="has-background-white has-border custom_scrollbar-1">
                                            <button @click.prevent="toggle_search(tj.guid, false)" class="delete is-pulled-right my-2 mr-1 is-small"></button>
                                            <table  class="table is-fullwidth has-background-white has-text-black is-narrow">
                                                <tbody>
                                                    <tr v-for="(mat, indexaa) in warehouse_job_materials(tj)" :key="indexaa">
                                                        <td>{{mat.Caption}}</td>
                                                        <td>{{mat.Qty}}</td>
                                                        <td width="100px"> <input type="text" class="input is-small is-info"> </td>
                                                        <td><a class="button is-small is-success"> <i class="fas fa-plus"></i> </a> </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <table class="table is-fullwidth general-component-wrap-background has-text-dark has-border is-narrow mb-0">
                                    <thead class="is-size-7">
                                        <tr>
                                            <th>Material</th>
                                            <th>Qty</th>
                                            <th class="has-text-right" width="20%">Loaded</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(material, index) in tj.Qty">
                                            <tr :key="index">
                                                <td>{{material.Caption}}</td>
                                                <td>{{material.Qty}}</td>
                                                <td>
                                                    <input v-model="material.loaded_amount" type="number" class="is-pulled-right has-text-centered input is-small is-info">

                                                </td>
                                                
                                            </tr>
                                            <tr :key="'M' + index" v-if="material.Qty > material.loaded_amount || material.Qty < material.loaded_amount">
                                                <td colspan="3">
                                                    <p v-if="material.Qty > material.loaded_amount" class="help is-warning has-text-right m-0">
                                                        <i class="fas fa-exclamation-triangle"></i>
                                                        Loaded amount is less than the planneed amount.
                                                    </p>
                                                    <p v-if="material.Qty < material.loaded_amount" class="help is-danger has-text-right m-0">
                                                        <i class="fas fa-exclamation-triangle"></i>
                                                        Loaded amount is greater than the planneed amount.
                                                    </p>

                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                                <div class="is-flex is-flex-direction-row-reverse" style="margin-top:auto;">
                                    <a v-if="tj.status_id == 1" @click.prevent="complete_truck_job_planning(tj)" href="" class="button is-success mt-2 is-small">
                                        Confirm loading
                                    </a>
                                    <a v-if="tj.status_id == 2"  @click.prevent="open_chekcin_modal(tj)" href="" class="button is-outlined is-success mt-2 is-small">
                                        Check in
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <small class="has-border p-2">
                        There are no loading trucks for this warehouse.
                    </small>
                </div>

            </div>
            <div v-else>
                <div class="columns is-centered">
                    <div class="column  is-flex ">
                        <div  class="notification is-warning is-light">
                            {{ $t('please_select_warehouse') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="ml-4 p-4 has-text-dark has-background-success-light">
            <i class="fa fa-spinner fa-spin"></i> Loading, please wait...
        </div>
        <loading-checkin-modal v-if="show_checkin_modal && selected_truck_job"></loading-checkin-modal>
    </div>
</template>

    <script>
    import axios from 'axios'
        import socket from '@/socket.js'
        import socketMixin from '@/socket-mixin.js';
    export default {
        mixins: [ socketMixin ],
        components:{
            'truck': () => import('@/components/steps/planning/truck.vue'),
            'loading-checkin-modal': () => import('@/components/steps/loading/loading-checkin-modal.vue'),
        },
        data() {
            return {
                // loading_truck_jobs: [],
                direction: true,
                selected_warehouse: {},
                all_truck_jobs: [],
                selected_warehouse_jobs: [],
                selected_warehouse_materials: [],
                search_material_field: '',
                all_project_parameters: [],
                warehouses_parameters: [],
                proj_trc_jobs_got: false,
                selected_truck_job: {},
                show_checkin_modal: false
            }
        },
        methods: {
            get_project_truck_jobs(evaluate){
                axios.post(this.$tetris_server + '/get/truck/jobs', {project_id: this.$parent.currentEvent})
                .then(res => {
                    this.all_truck_jobs = [...res.data.map(e => {
                        e.edit_mode = true
                        e.show_search = false
                        e.search_text = ''
                        e.details.arrives_at_original = e.details.arrives_at
                        e.details.departs_at_original = e.details.departs_at
                        return e
                    }).filter(tj => tj.status_id == 1 || tj.status_id ==2)]
                    if(evaluate){ 
                        // this.revaluateSelectedWarehouseJobs()
                    }

                    this.proj_trc_jobs_got = true
                })
            },
            getWarehouseName(id){
                let w = {}
                let warehouse_list = JSON.parse(localStorage.warehouses)
                warehouse_list.map(e => {
                    if(e.Id == id)
                        w = e
                })
                return w.Caption
            },
            

            select_warehouse(w){
                if(this.$parent.are_jobs_loaded){
                    this.selected_warehouse = []
                    this.selected_warehouse_jobs = []
                    this.selected_warehouse_materials = []
                    this.selected_warehouse = this.warehouses[w]
                    this.selected_warehouse_key = w
                    // Get jobs of selected_warehouse
                    if(this.direction)
                        this.selected_warehouse_jobs = [...this.$parent.jobs
                            .filter(e => e.Stock.ID == this.selected_warehouse.ID 
                                && !e.Caption.toLowerCase().includes('- request') // exclude the request job 
                                && e.Caption.toLowerCase().includes('- w:') // allow only the warehouse job
                            )
                            ].sort(this.$parent.sortDates)

                    else
                        this.selected_warehouse_jobs = [...this.$parent.jobs
                            .filter(e => 
                                !e.Caption.toLowerCase().includes('- request') // exclude the request job 
                                && e.Caption.toLowerCase().includes('- w:') // allow only the warehouse job
                            )
                            ].sort(this.$parent.sortDates)

                    // this.revaluateSelectedWarehouseJobs()

                    this.materials_selected_warehouse(this.selected_warehouse)

                    this.getWarehouseJobDetails()
                    
                    this.getNotesMaterial()
                }
            },
            materials_selected_warehouse(w){
                var swm = []
                this.$parent.jobs.map(j => {
                    j.materials.map(m => {
                        if( j.Stock.ID == w.ID)
                        {
                            m.job_id = j.ID
                            m.job_number = j.Number
                            m.warehouse_id = j.Stock.ID
                            swm.push(m)
                        }
                    })
                })
                this.selected_warehouse_materials = swm
                // return swm
            },
            getWarehouseJobDetails(){
                // Get projects_warehouse_jobs (departure - pickup date)
                axios.post(this.$tetris_server + '/all/project/warehouse/jobs', {project_id: this.$parent.currentEvent})
                .then(r => {
                    this.$parent.jobs = [...this.$parent.jobs.map(j => {
                        r.data.map(dd => {
                            if(j.IdJob == dd.ej_id){
                                j = {...j, departure_date: dd.departure_date, pickup_date: dd.pickup_date};
                            }
                        })
                        return j
                    })]
                    this.selected_warehouse_jobs = [...this.selected_warehouse_jobs.map(j => {
                        r.data.map(dd => {
                            if(j.IdJob == dd.ej_id){
                                j = {...j, departure_date: dd.departure_date, pickup_date: dd.pickup_date};
                            }
                        })
                        return j
                    })]
                    // this.projects_warehouse_jobs_data = [...r.data]
                    // this.revaluateSelectedWarehouseJobs()
                })
            },
            getNotesMaterial(){
                this.$parent.jobs = [...this.$parent.jobs.map(j => {
                    if(!j.Caption.toLowerCase().includes('- request')){
                        j.materials = [...j.materials.map(jm => {
                            this.$parent.jobs.map(rj => {
                                if(rj.Caption.toLowerCase().includes('- request')){
                                    rj.materials.map(m => {
                                        if(m.IdST == jm.IdST){
                                            m.date_amount.map(md => {
                                                if(md.warehouse_job == j.ID){ // && md.IdST == m.IdST)
                                                    jm.previous_notes = md.warehouse_notes + md.request_notes
                                                    jm.planning_notes = md.planning_notes
                                                    jm.m_guid = md.m_guid
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                            return jm
                        })];
                    }
                    return j
                })]
            },
            toggle_search(guid, toggle){
                // BUG - not changing show_search 
                this.all_truck_jobs = [...this.all_truck_jobs.map(tj => {
                    if(tj.guid == guid){
                        tj.show_search = toggle
                        tj.search_text = ''
                    }
                    return tj
                })]
            },
            getTrucksName(id, list){
                let a = list.filter(l => l.id == id)
                if(a.length == 1) {
                    let fields = JSON.parse(a[0].form_value)
                    let truck_name = fields[0].value
                    truck_name = truck_name ? truck_name : "" 
                    return truck_name + ( a[0].data ? (' ' + a[0].data.name) : '')
                }
                else return id
            },
            save_truck_job_details(d){
                setTimeout(() => {
                    if(d.arrives_at != d.arrives_at_original){
                        d.arrives_at = d.arrives_at_original
                    }
                    axios.post(this.$tetris_server + '/save/truck/job/details', {
                        details: d
                        })
                    .then(res => {
                        // this.stop_modal_data = {...d}
                        // this.$parent.revaluateSelectedWarehouseJobs()
        
                        if(res.data.length == 1){
                            d = {...res.data[0], arrives_at_original: res.data[0].arrives_at}
                        }

        
                        // this.$parent.project_trucks_jobs = [...this.$parent.project_trucks_jobs.map(tj =>{
                        //     // console.log(tj.guid, d.project_job_id)
                        //     if(tj.guid == d.project_job_id)
                        //         tj.details = {...d}
                        //     else {
                        //         tj.stops = tj.stops.map(s => {
                        //             if(s.guid == d.project_job_id) 
                        //                 s.details = {...s.details, ...d}
                        //             return s
                        //         })
                        //     }
                        //     return tj
                        // })]

                        // this.$parent.revaluateSelectedWarehouseJobs()

                        // socket.emit('tell others', {
                        //     type: 'edit truck header',
                        //     data: d
                        // })

                        this.$toast("Truck info saved", "info", 600)

                        // this.close_stop_modal()
                    })
                }, 900);
            },
            async complete_truck_job_planning(tj){
                var wj = {}
                wj = this.selected_warehouse_jobs.find(a => a.IdJob == tj.ej_id)
                let htmltext = ""
                if(tj.status_id == 0) 
                    htmltext = '<b>' + this.getTrucksName(tj.details.truck_id, this.warehouses_parameters) +'</b> '+ this.$t('sure_you_want_complete_planning_truck')
                else if (tj.status_id == 1 && this.$parent.direction)
                    htmltext = '<b>' + this.getTrucksName(tj.details.truck_id, this.warehouses_parameters) +'</b> '+ this.$t('sure_you_want_complete_loading_truck')
                else if(tj.status_id == 1 && !this.$parent.direction) 
                    htmltext = '<b>' + this.getTrucksName(tj.details.truck_id, this.warehouses_parameters) +'</b> '+ this.$t('sure_you_want_complete_loading_truck_return')
                this.$fire({
                    html: htmltext, 
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                    width: 300,
                    customClass: {
                        confirmButton: 'button is-success is-small',
                        cancelButton: 'button is-small',
                    },
                }).then(async response => {
                    if(response.value){
                        // if the truck / dates / delivery location has been set then save (only for status 1 and 2)
                        if(tj.details.id != null && tj.details.truck_id != null){
                            let departs_at = new Date()
                            let end_date = wj.pickup_date
                            axios.post(this.$tetris_server + '/complete/truck/job', {
                                truck_job: tj,
                                "departs_at": departs_at,
                                "end_date": end_date,
                                "direction": this.direction ? 0 : 1
                            })
                                .then(async () => {
                                    tj.status_id = tj.status_id + 1 
                                    if(tj.status_id == 1){
                                        this.$toast(this.$t('job_planning_completed'))
                                    }
                                    if(tj.status_id == 2){
                                        // TODO here it should: 
                                        // 1. create the jobs in easyjob if is a delivery and status_id == 2 -> was loaded to truck
                                        
                                        if(this.direction){
                                            //set the checkout date the time you are clicking on checkout
                                            let start_date = new Date()
                                            let end_date = wj.pickup_date
                                            tj.details.departs_at = start_date
                                            tj.details.arrives_at = end_date
                                            this.save_truck_job_details(tj.details)
                                            // 0. Should first create a project_job_tx for the checkout in Tetris and get its guid for the materials
                                            await axios.post(this.$tetris_server + '/save/truck/job/checkout', 
                                                {
                                                    ...tj,
                                                    "created_by": JSON.parse(localStorage.user).IdUser,
                                                    "end_date": end_date,
                                                    "start_date": start_date
                                                }
                                            )
                                            .then(async (tetris_checkout_response) => {
                                                // THIS ACTS AS A CONFIG to create multiple jobs or just one
                                                let multiple_jobs = false 


                                                let checkout_guid = tetris_checkout_response.data.guid

                                                let user = JSON.parse(localStorage.tetris_user)

                                                // TODO ERRORS HERE MUST BE CONFIRMED THIS WORKS!!! 
                                                let starts = this.$moment(tj.details.departs_at).add(user.timezone_offset, "hours") //.utc() //.add(0, "hours") // 
                                                let ends = this.$moment(wj.pickup_date).add(user.timezone_offset, "hours") //.utc()//.add(0, "hours") // 
                                                
                                                if(multiple_jobs){
                                                    // will create multiple jobs and will add a material for each of them
                                                    for (var i = 0; i < tj.Qty.length; i++) {
                                                        let job = {
                                                            Caption: this.$parent.event_details.Caption + ' - ' + this.selected_warehouse.Caption + ' - t:' + this.getTrucksName(tj.details.truck_id, this.warehouses_parameters), 
                                                            Address_Delivery: null, 
                                                            Contact_Delivery: null, 
                                                            DayTimeOut: starts, // tj.details.departs_at,
                                                            DayTimeIn: ends, //tj.end_date,
                                                            IdJob: null, 
                                                            JobState: {
                                                                ID: 1, // Status of the job should be confirmed in order to check it out  
                                                                IdJobState: 1 // same here 
                                                            },
                                                            Project: {
                                                                ID: this.$parent.currentEvent,
                                                                IdProject: this.$parent.currentEvent
                                                            },
                                                            Stock: {
                                                                ID: tj.warehouse_id,
                                                                IdStock: tj.warehouse_id
                                                            },
                                                        }
                                                        // [1] Save the job to easyjob
                                                        await this.$parent.postEJDatas('jobs/save/', job).then(response => {
                                                            if(response.data.Success == true){
                                                                let new_job = response.data.ID
                                                                // Add here the materials for each job
                                                                // Logic here is to add a job for each material
                                                                let new_material = {
                                                                    IdJob: new_job, 
                                                                    IdStockType: tj.Qty[i].IdST,
                                                                    Quantity: tj.Qty[i].loaded_amount,
                                                                }
                                                                // [2] Save each material in a single job
                                                                this.$parent.postEJDatas('items/book', new_material).then(() => {
                                                                    this.$parent.general_toaster = "[EASY JOB MATERIAL RESPONSE] ------------" 
                                                                    + "--------- " + response.data.Message;
                                                                    // after Booking the single material of the job
                                                                    // 2. checkout the job material
                                                                    this.$parent.postEJDatas('custom/jobcheckout/' + new_job, {})
                                                                        .then(checkout_response => {
                                                                            this.$parent.general_toaster = "[EASY JOB MATERIAL RESPONSE] ---------"
                                                                            + "------------ " + checkout_response.data.Message;
                                                                            // 3. TODO: Store the checkout in tetris database 
                                                                            // with possibly also the checkout id so can easily be used for the checkin back
                                                                            // Use the guid of the stored checkout / tx job for creating the materials
                                                                            axios.post(this.$tetris_server + '/checkout/material', {
                                                                                tx_id: checkout_guid,
                                                                                project_job_id: tj.guid,
                                                                                ej_id: new_job, 
                                                                                amount: new_material.Quantity, 
                                                                                material: new_material.IdStockType,
                                                                                start_date: start_date,
                                                                                end_date: end_date,
                                                                                warehouse_id: tj.warehouse_id

                                                                            })
                                                                                .then(() => {
                                                                                    // this.revaluateSelectedWarehouseJobs()
                                                                            });
                                                                        })
                                                                })
                                                            }
                                                            else {
                                                                // ERROR: job was not created
                                                                this.$parent.general_toaster = "[EASY JOB RESPONSE] ---------"
                                                                    + "------------ " + response.data.Message + ': ' + JSON.stringify(response.data.ErrorMessages);
                                                            }
                                                        })
                                                    } // end of cycle the materials 
                                                }
                                                else {
                                                    // creates a single job with all the materials in
                                                    let job = {
                                                            Caption: this.$parent.event_details.Caption + ' - ' + this.selected_warehouse.Caption + ' - t:' + this.getTrucksName(tj.details.truck_id, this.warehouses_parameters), 
                                                            Address_Delivery: null, 
                                                            Contact_Delivery: null, 
                                                            DayTimeOut: starts, // tj.details.departs_at,
                                                            DayTimeIn: ends, //tj.end_date,
                                                            IdJob: null, 
                                                            JobState: {
                                                                ID: 1, // Status of the job should be confirmed in order to check it out  
                                                                IdJobState: 1 // same here 
                                                            },
                                                            Project: {
                                                                ID: this.$parent.currentEvent,
                                                                IdProject: this.$parent.currentEvent
                                                            },
                                                            Stock: {
                                                                ID: tj.warehouse_id,
                                                                IdStock: tj.warehouse_id
                                                            },
                                                        }
                                                    // [1] Save the job to easyjob
                                                    await this.$parent.postEJDatas('jobs/save/', job).then(response => {
                                                        if(response.data.Success == true){
                                                            let new_job = response.data.ID
                                                            let materials_list = []
                                                            
                                                            for (var i = 0; i < tj.Qty.length; i++) {
                                                                materials_list.push( {
                                                                    IdJob: new_job, 
                                                                    IdStockType: tj.Qty[i].IdST,
                                                                    Quantity: tj.Qty[i].loaded_amount,
                                                                } )
                                                            }


                                                            this.$parent.postEJDatas('custom/batchbook/', materials_list).then(async new_material_request => {
                                                                console.log(new_material_request)
                                                                axios.post(this.$parent.$tetris_server + '/checkout/materials', {
                                                                    tx_id: checkout_guid,
                                                                    project_job_id: tj.guid,
                                                                    ej_id: new_job, 
                                                                    materials: materials_list,
                                                                    start_date: start_date,
                                                                    end_date: end_date,
                                                                    warehouse_id: tj.warehouse_id

                                                                }).then(() => {
                                                                        // this.revaluateSelectedWarehouseJobs()
                                                                });

                                                                    

                                                            })
                                                        }
                                                    })

                                                }
                                            });
                                                                                            
                                        }
                                        else {
                                            //revaluate check-in data too
                                            // this.revaluateSelectedWarehouseJobs()
                                        }
                                        this.$toast(this.$t('job_loading_completed'))
                                    }
                                    // tell the others about the loading of the job
                                    socket.emit('tell others', { type: 'job status changed', data: tj, })
                                    this.all_truck_jobs = [...this.all_truck_jobs.filter(t => t.guid != tj.guid)]
                                })							
                            }
                        else {
                            alert(this.$t('specify_job_details'))
                        }
                    }
                })
            },
            // async complete_truck_job_planning_backup(tj){
            //     var wj = {}
            //     wj = this.selected_warehouse_jobs.find(a => a.IdJob == tj.ej_id)
            //     let htmltext = ""
            //     if(tj.status_id == 0) 
            //         htmltext = '<b>' + this.getTrucksName(tj.details.truck_id, this.warehouses_parameters) +'</b> '+ this.$t('sure_you_want_complete_planning_truck')
            //     else if (tj.status_id == 1 && this.direction)
            //         htmltext = '<b>' + this.getTrucksName(tj.details.truck_id, this.warehouses_parameters) +'</b> '+ this.$t('sure_you_want_complete_loading_truck')
            //     else if(tj.status_id == 1 && !this.direction) 
            //         htmltext = '<b>' + this.getTrucksName(tj.details.truck_id, this.warehouses_parameters) +'</b> '+ this.$t('sure_you_want_complete_loading_truck_return')
            //     this.$fire({
            //         html: htmltext, 
            //         showCancelButton: true,
            //         confirmButtonText: this.$t('yes'),
            //         cancelButtonText: this.$t('no'),
            //         width: 300,
            //         customClass: {
            //             confirmButton: 'button is-success is-small',
            //             cancelButton: 'button is-small',
            //         },
            //     }).then(async response => {
            //         if(response.value){
            //             // if the truck / dates / delivery location has been set then save (only for status 1 and 2)
            //             if(tj.details.id != null && tj.details.truck_id != null){
            //                 let departs_at = new Date()
            //                 let end_date = wj.pickup_date
            //                 axios.post(this.$tetris_server + '/complete/truck/job', {
            //                     truck_job: tj,
            //                     "departs_at": departs_at,
            //                     "end_date": end_date,
            //                     "direction": this.direction ? 0 : 1
            //                 })
            //                     .then(async () => {
            //                         tj.status_id = tj.status_id + 1 
            //                         if(tj.status_id == 1){
            //                             this.$toast(this.$t('job_planning_completed'))
            //                         }
            //                         if(tj.status_id == 2){
            //                             // TODO here it should: 
            //                             // 1. create the jobs in easyjob if is a delivery and status_id == 2 -> was loaded to truck
                                        
            //                             if(this.direction){
            //                                 //set the checkout date the time you are clicking on checkout
            //                                 let start_date = new Date()
            //                                 let end_date = wj.pickup_date
            //                                 tj.details.departs_at = start_date
            //                                 tj.details.arrives_at = end_date
            //                                 this.save_truck_job_details(tj.details)
            //                                 // 0. Should first create a project_job_tx for the checkout in Tetris and get its guid for the materials
            //                                 await axios.post(this.$tetris_server + '/save/truck/job/checkout', 
            //                                     {
            //                                         ...tj,
            //                                         "created_by": JSON.parse(localStorage.user).IdUser,
            //                                         "end_date": end_date,
            //                                         "start_date": start_date
            //                                     }
            //                                 )
            //                                 .then(async (tetris_checkout_response) => {
            //                                     let checkout_guid = tetris_checkout_response.data.guid

            //                                     let user = JSON.parse(localStorage.tetris_user)

            //                                     // TODO ERRORS HERE MUST BE CONFIRMED THIS WORKS!!! 
            //                                     let starts = this.$moment(tj.details.departs_at).add(user.timezone_offset, "hours") //.utc() //.add(0, "hours") // 
            //                                     let ends = this.$moment(wj.pickup_date).add(user.timezone_offset, "hours") //.utc()//.add(0, "hours") // 

            //                                     for (var i = 0; i < tj.Qty.length; i++) {
            //                                         let job = {
            //                                             Caption: this.$parent.event_details.Caption + ' - ' + this.selected_warehouse.Caption + ' - t:' + this.getTrucksName(tj.details.truck_id, this.warehouses_parameters), 
            //                                             Address_Delivery: null, 
            //                                             Contact_Delivery: null, 
            //                                             DayTimeOut: starts, // tj.details.departs_at,
            //                                             DayTimeIn: ends, //tj.end_date,
            //                                             IdJob: null, 
            //                                             JobState: {
            //                                                 ID: 1, // Status of the job should be confirmed in order to check it out  
            //                                                 IdJobState: 1 // same here 
            //                                             },
            //                                             Project: {
            //                                                 ID: this.$parent.currentEvent,
            //                                                 IdProject: this.$parent.currentEvent
            //                                             },
            //                                             Stock: {
            //                                                 ID: tj.warehouse_id,
            //                                                 IdStock: tj.warehouse_id
            //                                             },
            //                                         }
            //                                         // [1] Save the job to easyjob
            //                                         await this.$parent.postEJDatas('jobs/save/', job).then(response => {
            //                                             if(response.data.Success == true){
            //                                                 let new_job = response.data.ID
            //                                                 // Add here the materials for each job
            //                                                 // Logic here is to add a job for each material
            //                                                 let new_material = {
            //                                                     IdJob: new_job, 
            //                                                     IdStockType: tj.Qty[i].IdST,
            //                                                     Quantity: tj.Qty[i].loaded_amount,
            //                                                 }
            //                                                 // [2] Save each material in a single job
            //                                                 this.$parent.postEJDatas('items/book', new_material).then(() => {
            //                                                     this.$parent.general_toaster = "[EASY JOB MATERIAL RESPONSE] ------------" 
            //                                                     + "--------- " + response.data.Message;
            //                                                     // after Booking the single material of the job
            //                                                     // 2. checkout the job material
            //                                                     this.$parent.postEJDatas('custom/jobcheckout/' + new_job, {})
            //                                                         .then(checkout_response => {
            //                                                             this.$parent.general_toaster = "[EASY JOB MATERIAL RESPONSE] ---------"
            //                                                             + "------------ " + checkout_response.data.Message;
            //                                                             // 3. TODO: Store the checkout in tetris database 
            //                                                             // with possibly also the checkout id so can easily be used for the checkin back
            //                                                             // Use the guid of the stored checkout / tx job for creating the materials
            //                                                             axios.post(this.$tetris_server + '/checkout/material', {
            //                                                                 tx_id: checkout_guid,
            //                                                                 project_job_id: tj.guid,
            //                                                                 ej_id: new_job, 
            //                                                                 amount: new_material.Quantity, 
            //                                                                 material: new_material.IdStockType,
            //                                                                 start_date: start_date,
            //                                                                 end_date: end_date,
            //                                                                 warehouse_id: tj.warehouse_id

            //                                                             })
            //                                                                 .then(() => {
            //                                                                     // this.revaluateSelectedWarehouseJobs()
            //                                                             });
            //                                                         })
            //                                                 })
            //                                             }
            //                                             else {
            //                                                 // ERROR: job was not created
            //                                                 this.$parent.general_toaster = "[EASY JOB RESPONSE] ---------"
            //                                                     + "------------ " + response.data.Message + ': ' + JSON.stringify(response.data.ErrorMessages);
            //                                             }
            //                                         })
            //                                     } // end of cycle the materials 
            //                                 });
                                                                                            
            //                             }
            //                             else {
            //                                 //revaluate check-in data too
            //                                 // this.revaluateSelectedWarehouseJobs()
            //                             }

            //                             this.$toast(this.$t('job_loading_completed'))
            //                             this.all_truck_jobs = [...this.all_truck_jobs.filter(t => t.guid != tj.guid)]
            //                         }
            //                         // tell the others about the loading of the job
            //                         socket.emit('tell others', { type: 'job status changed', data: tj, })
            //                     })							
            //                 }
            //             else {
            //                 alert(this.$t('specify_job_details'))
            //             }
            //         }
            //     })
            // },
            
            
            getMaterialData(m){
                let mat = {}
                this.$parent.materials_data.forEach(material => {
                    if(material.IdStockType == m.IdST)
                        mat = material
                })
                return mat
            },
            truckJobWeightVolume(t) {
                let d = {
                    Weight: 0,
                    Height: 0,
                    Width: 0,
                    Depth: 0,
                }
                t.Qty.forEach(e => {
                    d.Weight = parseFloat(d.Weight) + parseFloat(this.getMaterialData(e).Weight)*(t.status_id == 0 ? e.Qty : e.loaded_amount)
                    d.Height = parseFloat(d.Height) + parseFloat(this.getMaterialData(e).Height)*(t.status_id == 0 ? e.Qty : e.loaded_amount)
                    d.Width = parseFloat(d.Width) + parseFloat(this.getMaterialData(e).Width)*(t.status_id == 0 ? e.Qty : e.loaded_amount)
                    d.Depth = parseFloat(d.Depth) + parseFloat(this.getMaterialData(e).Depth)*(t.status_id == 0 ? e.Qty : e.loaded_amount)
                })
                return d
            },
            get_project_parameters(){
                axios.post(this.$tetris_server + '/get/project/warehouse/parameters', {project_id: this.$parent.currentEvent })
                .then(r => {
                    this.all_project_parameters = r.data
                    this.warehouses_parameters = r.data.map(d => {
                        d.value = this.getPreviewFields(JSON.parse(d.form_value), "")
                        return d
                    })
                })
            },
            getPreviewFields(fields, prefix){
                let r = ""
                fields.map(f => {
                    if(f.preview == true) r += (f.value ? f.value : this.$t('missing_value_general') ) + ', '
                    return f 
                })
                return r == "" ? /*fields[0].value*/ '' : prefix + r.substring(0, r.length-2)
            },
            getTruckData(id, list){
                let a = list.filter(l => l.id == id)
                if(a.length == 1) {
                    let fields = JSON.parse(a[0].form_value)
                    return { data: a[0].data, fields}
                }
                else return {}
            },
            getParameterVisibleFields(id, list, token, short = false){
                let a = list.filter(l => l.id == id)
                if(a.length == 1) {
                    let fields = JSON.parse(a[0].form_value)
                    let truck_name = "" 
                    fields.map(f => {
                        if(f.value && f.preview && !short) truck_name += f.value + token
                        else if (f.value && f.preview && short && truck_name == "") 
                            truck_name = f.value
                    })
                    truck_name = truck_name ? truck_name : "" 
                    
                    if(short){
                        return a[0].data ?  a[0].data.name : truck_name
                    }				
                    return truck_name + ( a[0].data ? (' ' + a[0].data.name) : '')
                }
                else return id
            },
            destination_name(id){
                var v = this.warehouses_parameters.filter(p => p.id == id)  
                if(v.length > 0){
                    return v[0].value
                }
            },
        
            sumsBy(list, key, sums) {
                let unique_list = []
                console.log('list', list)
                list.map(e => {
                    let fount = -1
                    unique_list.map((m, idx) => {
                        if(m[key] == e[key])
                            fount = idx
                    })
                    if(fount == -1) unique_list.push({...e})
                    else { 
                        unique_list[fount][sums] = unique_list[fount][sums] + e[sums]
                        unique_list[fount]["QtyWas"] = unique_list[fount]["QtyWas"] + e["QtyWas"]
                    }
                })
                return unique_list
            },
            warehouse_job_materials(tj){
                return this.selected_warehouse_jobs_materials.filter(a => a.IdJob == tj.ej_id)[0].materials.filter((obj) =>{
                    if(tj.search_text != ''){
                        return JSON.stringify(obj).toLowerCase().includes(tj.search_text.toLowerCase())
                    } else return true
                })
            },
            open_chekcin_modal(tj){
                this.selected_truck_job = tj
                if(this.selected_truck_job.tx_guid){
                    // Get TX
                    axios.post(this.$tetris_server + '/get/job/tx', {
                        tx_guid: this.selected_truck_job.tx_guid,
                    }).then(r => {
                        this.selected_truck_job.tx_data = r.data[0]
                        this.selected_truck_job.tx_guid = r.data[0].guid
                    })
                }
                // console.log('STJ', this.selected_truck_job)
                this.show_checkin_modal = !this.show_checkin_modal 
            },
        },
        computed:{
            warehouses(){
                var l = {} 
                this.$parent.jobs_warehouses.forEach(j => {
                    if(!l[j.Stock.ID]){
                        l[j.Stock.ID] = {} 
                    }
                    l[j.Stock.ID] = j.Stock
                })
                return l
            },
            truck_job_list(){
                // console.log('this.all_truck_jobs', this.all_truck_jobs, this.direction)
                return this.all_truck_jobs.map(tt => {
                    tt.Qty.map(tq => {
                        if(!tq.loaded_amount && typeof(tq.loaded_amount) != 'string') 
                            tq.loaded_amount = tq.Qty 
                        return tq
                    })
                    return tt
                }).filter(tj => {
                    if(this.direction && parseInt(tj.warehouse_id) == this.selected_warehouse.ID) return true
                    else if(!this.direction) return true
                })
                .filter(t => t.direction == !this.direction)
                .filter(td => {
                    if(this.direction && td.status_id == 1) return true
                    else if(!this.direction && (td.status_id == 1 || td.status_id == 2)) return true
                })
            },
            search_result(){
                return this.$parent.materials_data.filter((obj) =>{
                    if(this.search_material_field != ''){
                        return JSON.stringify(obj).toLowerCase().includes(this.search_material_field.toLowerCase())
                    } else return false
                })
            },
            materials_1(){
                var mat = []
                this.$parent.jobs.map(j => {
                    j.materials.map(m => {
                        m.job_id = j.ID
                        m.warehouse_id = j.Stock.ID

                        mat.push(m)
                    })
                })
                // console.log('mat', mat)
                return mat
            },
            selected_warehouse_jobs_materials(){
                let r = []
                let returning_dates = {}
                if(this.direction){
                return this.selected_warehouse_jobs
                } else {
                    console.log('222', this.selected_warehouse_jobs)
                    this.selected_warehouse_jobs.map(wj => {
                        if(!returning_dates['aaaaaa'])
                            returning_dates['aaaaaa'] = []

                        returning_dates['aaaaaa'].push(wj)
                        /// here create group based on DayTimeIn
                        let warehouse_job = {...wj, truck_jobs: [], returning_jobs: [], showTruckJobsList: false}
                        wj.truck_jobs.forEach(tj => {
                            if(tj.direction == 0) {
                                warehouse_job.truck_jobs.push(tj)
                            }
                        })
                        wj.returning_jobs.map(tj => {
                            if(tj.direction == 1)
                                warehouse_job.returning_jobs.push(tj)
                        })

                        // if(warehouse_job.truck_jobs.length > 0)
                        // r.push({ ...warehouse_job, truck_jobs: []})
                    })
                    console.log('returning dates', returning_dates)
                    Object.keys(returning_dates).map(e => {
                        let j = {}
                        returning_dates[e].map(job => {
                            if(!j.ID) j = {...job, status_id: 3} // What is this??!?!?!?!?!
                            else j.materials = [...j.materials.concat(job.materials)]
                        })
                        let list = [...j.materials] //.filter(t => t.LoadedAmount > 0)]

                        j.materials = this.sumsBy(list, 'IdST', 'LoadedAmount')
                        if(j.materials.length > 0)
                            r.push({...j})
                    })
                    return r
                }
            },
        },
        mounted(){
            socket.on('someone said', this.socket_listener)
            if(this.$parent.event_jobs.length == 0) this.$parent.getWarehouseJobDetails()
        },
        beforeDestroy() {
            socket.off("someone said", this.socket_listener)
        },
        created(){
            this.proj_trc_jobs_got = false
            this.$document.title = "Loading Trucks"
                if(this.$route.params.id && this.$parent.event_jobs.length == 0) {
                    this.$parent.event_jobs = []
                    this.$parent.event_details.Jobs = []
                    this.$parent.currentEvent = this.$route.params.id 
                    this.$parent.getProject()	
                }
            // if(this.$route.params.id && this.$parent.event_jobs.length == 0) {
            //     this.$parent.currentEvent = this.$route.params.id 
            //     this.$parent.getProject()	
            // }

            if(this.$parent.currentEvent){
                this.get_project_truck_jobs()
                this.get_project_parameters()
            }

            // this.get_loading_truck_jobs()
            this.$parent.app_lang = this.$i18n.locale
            this.$parent.app_route = this.$parent.get_url()
        }

}
</script>

<style>

</style>